/**
 * @type {{wordPressUrl: string}}
 */
const config = {
  wordPressUrl: `https://dragons.kinsta.cloud/`,
  // wordPressUrl: `https://dragons-new.gatsby-wp.com/`,

  // wordPressUrl: `http://localhost:10038/`,
  siteUrl: `https://knowyourdragons.com/`,
}

module.exports = config
