export const text = {
  branding: {},
  heading: {},
  error: {
    textAlign: "center",
    m: 0,
    px: 30,
    py: 15,
    bg: "orange",
    borderRadius: 10,
    color: "#fff",
    mt: 20,
    fontWeight: "bold",
  },
}
