const grey = "#2f4e4d"
const lightGrey = "#f2f2f2"
const mediumGrey = "#979797"
const orange = "#ecdd2e"
const green = "#528e89"

export const colors = {
  grey,
  orange,
  green,
  lightGrey,
  mediumGrey,
  borderColor: mediumGrey,
  primary: green,
  secondary: orange,
  text: grey,
  background: "#fff",
}
